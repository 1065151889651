import React from "react";
import PropTypes from "prop-types";

import ChatAssistant from "_ai/components/ChatAssistant";
import waffle from "_common/waffle";

import { ReportsDeprecationBanner } from "reports/components/common/ReportsDeprecationBanner";

import Header from "./header";
import Breadcrumbs from "./breadcrumbs";
import Main from "./main";
import Footer from "./footer";
import CheckPerms from "../check-perms";
import SupportRequest from "../support-request";

/**
 * Skeleton page used by special areas such as Login.
 */
export const BarePage = (props) => (
  <div className="sde-page">{props.children}</div>
);

/**
 * Standard page holder used by most pages.
 */
const Page = (props) => {
  const isGlobalReports =
    window.location.pathname.includes("/reporting/global/");
  const isTrainingReport = window.location.pathname.includes(
    "/reporting/training/"
  );

  let contents;
  if (!props.headerOnly) {
    contents = (
      <div>
        <Breadcrumbs />
        {isGlobalReports && <ReportsDeprecationBanner reportType="global" />}
        <Main>{props.children}</Main>
        {!props.bare && (
          <>
            <Footer releaseName={props.releaseName} />
            {waffle.flag_is_active("ENABLE_AI_CHAT") ? (
              <ChatAssistant />
            ) : (
              <CheckPerms requireSuperuser>
                <SupportRequest />
              </CheckPerms>
            )}
          </>
        )}
      </div>
    );
  } else {
    contents = (
      <>
        {isGlobalReports && <ReportsDeprecationBanner reportType="global" />}
        {isTrainingReport && <ReportsDeprecationBanner reportType="training" />}
        {props.children}
      </>
    );
  }

  return (
    <BarePage>
      {!props.bare && (
        <Header
          logo={props.logo}
          user={props.user}
          fab={props.fab}
          navigationItems={props.navigationItems}
          subNavigationItems={props.subNavigationItems}
          userMenuItems={props.userMenuItems}
          target={props.target}
        />
      )}
      {contents}
    </BarePage>
  );
};

Page.propTypes = {
  logo: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    src: PropTypes.string,
  }),
  navigationItems: PropTypes.array,
  releaseName: PropTypes.string,
  subNavigationItems: PropTypes.array,
  userMenuItems: PropTypes.object,
};

Page.defaultProps = {
  headerOnly: false,
  bare: false,
};

export default Page;
